<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>

    <b-alert variant="warning" v-if="data == null" show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert
    >

    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Төлөв</label>
                <select class="form-control" v-model="status">
                  <option
                    :value="value"
                    v-for="(name, value, index) in status_list"
                    :key="index"
                    >{{ name }}</option
                  >
                </select>
              </div>
              <div class="form-group" v-if="status == 'UN_CONFIRM'">
                <label for="">Бусад</label>
                {{ un_confirm_other }}
                <select class="form-control" v-model="un_confirm_other">
                  <option
                    :value="item.id"
                    v-for="(item, index) in un_confirm_other_list"
                    :key="index"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="">Тайлбар</label>
                <textarea class="form-control" v-model="description"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="changeStatus">
                  Хадгадах
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row mb-0">
            <div class="col-md-6">
              <b-card>
                <loading
                  :active.sync="isLoadingFile"
                  :is-full-page="false"
                  :color="this.$config.LOADER.color"
                  :opacity="this.$config.LOADER.opacity"
                  :background-color="this.$config.LOADER.backgroundColor"
                  :z-index="10000"
                  :width="30"
                  :height="30"
                ></loading>
                <div class="form-group">
                  <label for="">Ярилцлагын тэмдэглэл</label>
                  <b-form-file
                    v-model="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary" @click="uploadMeetNote">
                    Хадгадах
                  </button>
                  <a
                    :href="file_link"
                    v-if="file_link"
                    class="pull-right btn btn-info"
                    >Файл татах</a
                  >
                </div>
                <div class="form-group mb-0  mt-3" v-if="file_message">
                  <b-alert class="mb-0" show :variant="file_message.variant">{{
                    file_message.text
                  }}</b-alert>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div
          class="col-md-12"
          v-html="data"
          style="border-left: 1px solid #ddd"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Auto.loanRequest.detail",
  components: { axios },
  data: function() {
    return {
      isLoadingFile: false,
      isLoading: false,
      id: this.$route.params.id,
      data: null,
      json: {},
      isLoading: false,
      form: {
        description: ""
      },
      status_list: [],
      status: null,
      description: "",
      a: 0,
      file: null,
      file_link: null,
      file_message: null
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    id: {
      handler(id) {
        this.loadData();
      },
      deep: true
    }
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;

      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/get_init_data/", {
          params: { id: this.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            console.log(response);
            this.$data.status_list = response.data.status_list;

            this.$data.status = response.data.status;
            this.$data.id = response.data.id;
            this.loadView();
            this.isLoading = false;
            this.getMeetNote();
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    loadView: function() {
      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/loan_request_view/", {
          params: { id: this.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            // //console.logresponse);
            this.$data.data = response.data;
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    getMeetNote: function() {
      this.isLoadingFile = true;
      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/get_meet_note/", {
          params: { id: this.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            // //console.logresponse);
            if (response.data.success) {
              this.$data.file_link = response.data.file_link;
            }

            this.isLoadingFile = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoadingFile = false;
          }
        );
    },
    uploadMeetNote: function() {
      this.isLoadingFile = true;
      let _this = this;
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.$data.file);
        formData.append("id", this.$data.id);
        axios
          .post(
            this.$config.OYUTAN_API_URL + "ajax/upload_meet_note/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(function(response) {
            if (response.data.success) {
              _this.file_message = {
                variant: "success",
                text: "Амжилттай хадгаллаа"
              };
              _this.getMeetNote();
            } else {
              _this.file_message = {
                variant: "warning",
                text: response.data.message
              };
            }
            _this.isLoadingFile = false;
          })
          .catch(function(response) {
            _this.file_message = {
              variant: "danger",
              text: "Алдаа гарсан байна"
            };
            _this.isLoadingFile = false;
          });
      } else {
        _this.file_message = {
          variant: "warning",
          text: "Файл сонгоогүй байна"
        };
      }
    },
    changeStatus: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.OYUTAN_API_URL + "ajax/change_status/", {
          params: {
            id: this.$data.id,
            status: this.$data.status,
            desc: this.$data.description
          },
          emulateJSON: true
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    }
  }
};
</script>
